@font-face {
  font-family: Oswald;
  font-weight: 300;
  src: url('/fonts/Oswald-Light.ttf') format('truetype');
}

@font-face {
  font-family: Oswald;
  font-weight: 400;
  src: url('/fonts/Oswald-Regular.ttf') format('truetype');
}

@font-face {
  font-family: Oswald;
  font-weight: 500;
  src: url('/fonts/Oswald-Medium.ttf') format('truetype');
}

@font-face {
  font-family: Oswald;
  font-weight: 700;
  src: url('/fonts/Oswald-Bold.ttf') format('truetype');
}

:root {
  --kelsey-dark: #151515;
  --kelsey-white: #fff;
  --kelsey-blue: #005b94;
  --kelsey-blue-light: #268ECF;
  --kelsey-red: #af0e14;
  --kelsey-grey: #777;
  --kelsey-dark-grey:  #3d3d3d;
  --kelsey-bg: #000000;
  --swiper-pagination-bullet-size: 24px;
  --swiper-pagination-color: #af0e14;
  --swiper-pagination-bullet-inactive-color: #fff;
  --swiper-pagination-bullet-inactive-opacity: .5;
}

body {
  font-family: Oswald, sans-serif;

  &::-webkit-scrollbar {
    display: block;
    background: transparent;
  }
}
